<template>
    <Card>
        <div slot="title">{{ title }}</div>
        <Card title="佣金结算设置" dis-hover>
            <Tag color="primary" style="margin-left: 20px;">省代理结算设置</Tag>
            <div class="form_item">
                <div class="form_label must_input">结算时间(单位:天)</div>
                <!-- <Select v-model="formData.province_settle_days" style="width: 200px">
                    <Option v-for="(item,index) in jsList" :value="item.value" :key="index">{{ item.label }}</Option>
                </Select> -->
                <InputNumber  v-model="formData.province_settle_days"  />
            </div>
            <div class="form_item">
                <div class="form_label must_input">单笔税金</div>
                <InputNumber :max="100" v-model="formData.province_tax_rate" :formatter="value => `${value}%`" :parser="value => value.replace('%', '')" />
            </div>
            <Tag color="primary" style="margin-left: 20px;margin-top: 20px;">经销商结算设置</Tag>
            <div class="form_item">
                <div class="form_label must_input">结算时间(单位:天)</div>
                <!-- <Select v-model="formData.dealer_settle_days" style="width: 200px">
                    <Option v-for="(item,index) in jsList" :value="item.value" :key="index">{{ item.label }}</Option>
                </Select> -->
                <InputNumber  v-model="formData.dealer_settle_days"  />
            </div>
            <div class="form_item">
                <div class="form_label must_input">单笔税金</div>
                <div v-if="isDetail == 'true'">{{ formData.dealer_rate }}</div>
                <InputNumber :max="100" v-model="formData.dealer_tax_rate" :formatter="value => `${value}%`" :parser="value => value.replace('%', '')" />
            </div>
            <Tag color="primary" style="margin-left: 20px;margin-top: 20px;">个人/小B端结算设置</Tag>
            <div class="form_item">
                <div class="form_label must_input">结算时间(单位:天)</div>
                <!-- <Select v-model="formData.person_settle_days" style="width: 200px">
                    <Option v-for="(item,index) in jsList" :value="item.value" :key="index">{{ item.label }}</Option>
                </Select> -->
                <!-- <DatePicker type="datetime" v-model="formData.publish_at" placeholder="请选择结算时间" style="width: 200px" /> -->
                <InputNumber  v-model="formData.person_settle_days"  />
            </div>
            <div class="form_item">
                <div class="form_label must_input">单笔税金</div>
                <InputNumber :max="100" v-model="formData.person_tax_rate" :formatter="value => `${value}%`" :parser="value => value.replace('%', '')" />
            </div>
        </Card>
        <Card title="佣金体现设置" dis-hover>
            <div class="form_item">
                <div class="form_label must_input">单笔最大金额</div>
                <!-- <div v-if="isDetail == 'true'">{{ formData.wd_amout_single_max }}</div> -->
                <InputNumber :max="100" v-model="formData.wd_amount_single_max" :formatter="value => `${value}元`" :parser="value => value.replace('元', '')" />
            </div>
            <div class="form_item">
                <div class="form_label must_input">单笔最小金额</div>
                <InputNumber :max="100" v-model="formData.wd_amount_single_min" :formatter="value => `${value}元`" :parser="value => value.replace('元', '')" />
            </div>
            <div class="form_item">
                <div class="form_label must_input">每日额度</div>
                <InputNumber :max="100" v-model="formData.wd_amount_day_max" :formatter="value => `${value}元`" :parser="value => value.replace('元', '')" />
            </div>
            <div class="form_item">
                <div class="form_label must_input">提现费率</div>
                <!-- <div v-if="isDetail == 'true'">{{ formData.wd_rate  }}</div> -->
                <InputNumber :max="100" v-model="formData.wd_rate" :formatter="value => `${value}%`" :parser="value => value.replace('%', '')" />
            </div>
        </Card>
        <Card title="佣金审批设置" dis-hover>
            <!-- <div class="form_item">
                <div class="form_label must_input">是否审批</div>
                <Select v-model="formData.person_over_exam" style="width: 200px">
                    <Option v-for="(item,index) in cczunitList" :value="item.value" :key="index">{{ item.label }}</Option>
                </Select>
            </div> -->
            <div class="form_item">
                <div class="form_label must_input">审批条件</div>
                <div v-if="isDetail == 'true'">{{ formData.dealer_rate }}</div>
                <InputNumber :max="100" v-model="formData.wd_amount_check_min" placeholder="请输入审批金额,>=需要审批" :formatter="value => `${value}元`" :parser="value => value.replace('元', '')" />
                <span style="margin-left: 5px;">(请输入审批金额,>=需要审批)</span>
            </div>
        </Card>
        <div class="mt_50 flex align_center justify_center form_item">
            <i-button type="primary" ghost @click="onCloseEdit">返回</i-button>
            <i-button class="ml_50" type="success" :loading="ajaxLoading" @click="onConfirm">确定</i-button>
        </div>
        <Modal :closable="false" v-model="showCreate" :title="title" width="500" :loading="ajaxLoading">
            <Tabs type="card" height="246" @on-click="tabsClick" style="z-index: 66666">
                <TabPane label="单选题" name="tab1" context-menu></TabPane>
                <TabPane label="多选题" name="tab2" context-menu></TabPane>
                <TabPane label="问答题" name="tab3" context-menu></TabPane>
                <TabPane label="比重题" name="tab3" context-menu></TabPane>
            </Tabs>
            <div class="form_item" style="margin-left: -118px">
                <div class="form_label must_input">问题标题</div>
                <Input v-model="formData.new_password"></Input>
            </div>
            <div style="display: flex; flex-direction: column; margin-top: 20px">
                <CheckboxGroup v-model="fruit" style="display: flex; flex-direction: column">
                    <div style="display: flex; margin: 5px 0">
                        <Checkbox label=""></Checkbox>
                        <Input placeholder="请输入选项" v-model="formData.new_password"></Input>
                    </div>
                    <div style="display: flex; margin: 5px 0">
                        <Checkbox label=""></Checkbox>
                        <Input placeholder="请输入选项" v-model="formData.new_password"></Input>
                    </div>
                    <div style="display: flex; margin: 5px 0">
                        <Checkbox label=""></Checkbox>
                        <Input placeholder="请输入选项" v-model="formData.new_password"></Input>
                    </div>
                </CheckboxGroup>
            </div>
            <div slot="footer" class="relative width_100_percent flex align_center justify_center">
                <!-- <Button type="primary" ghost @click="onCancelCreate">取消</Button> -->
                <Button class="ml_100" type="primary" :loading="ajaxLoading" @click="onConfirmCreate">确定</Button>
            </div>
        </Modal>
        <mySpin :loading="ajaxLoading"></mySpin>
    </Card>
</template>
<script>
import { reqGroupList, addAgent, updateAgent, readAgent, saveRule,saveFinanceRule,getFinanceRule } from '@/lib/request/auth';
import city from '@/components/city';
import { reqMerchantCreateAccount, reqMerchantSaveAccount } from '@/lib/request/auth2';
import { getCityTitles } from '@/lib/city.data';
import UploadImage from '@/components/uploadImage';

export default {
    name: 'serviceProviderAdd',
    components: {
        city,
        UploadImage,
    },
    data() {
        return {
            value1: '',
            value2: '',
            value3:'',
            value4:'',
            jsList:[
                // {
                //     value: '0',
                //     label: '立即结算(订单完成即结算)',
                // },
                {
                    value: '1',
                    label: '一天',
                },
                {
                    value: '2',
                    label: '两天',
                },
                {
                    value: '3',
                    label: '三天',
                },
                {
                    value: '4',
                    label: '四天'
                },
                {
                    value: '5',
                    label: '五天',
                },
                {
                    value: '6',
                    label: '六天',
                },
                {
                    value: '7',
                    label: '七天',
                }
            ],
            ccunitList: [
                {
                    value: '总部',
                    label: '总部',
                },
                {
                    value: '省代理',
                    label: '省代理',
                },
            ],
            cczunitList: [
                {
                    value: '0',
                    label: '条件审批',
                },
                {
                    value: '1',
                    label: '不审批',
                },
            ],
            numList:[
                {
                    value: '10',
                    label: '10',
                },
                {
                    value: '20',
                    label: '20',
                },
                {
                    value: '50',
                    label: '50',
                },
                {
                    value: '100',
                    label: '100',
                },
                {
                    value: '不限制',
                    label: '不限制',
                },
            ],
            model: '',
            showCreate: false,
            ratevalue: 0,
            fruit: '',
            vertical: '',
            title: '添加题目',
            formData: {
                wd_rate:1,
                wd_amount_single_max:1,
                wd_amount_day_max:1,
                wd_amount_check_min:1,
                wd_amount_single_min:1,
                province_settle_rate:0,
                dealer_settle_rate:0,
                penrson_settle_rate:0
            },
            address_code: [],
            address_note: null,
            roleList: [],
            address: [],
            isEdit: false,
            isDetail: false,
            arrPic: [],
            
        };
    },
    mounted() {
        this.routeParamsInjectFormData(['address_code', 'address_note']);
        this.isEdit = this.$route.query.isEdit;
        this.formData.id = this.$route.query.id;
        this.isDetail = this.$route.query.isDetail;
        console.log(this.$route.query.isEdit, 'this.$route.query.isEdit');
        this.title = this.isEdit == 'true' ? '财务设置' : '财务设置';
        this.getRoleList();
        this.getDetail();
        this.getcwDetail();

    },
    watch: {
        //
    },
    methods: {
        getcwDetail(){
            getFinanceRule().then(res=>{
                this.formData = res.data;
                console.log(res,'res')
            })
        },
        tabsClick(name) {},
        onConfirmCreate() {},
        onAdd() {
            this.showCreate = true;
        },
        //单个图片上传成功
        onSuccessUploadImg() {
            if (this.arrPic.length < this.maxLength && hm.isNotEmptyString(this.arrPic.slice(-1)[0])) {
                this.arrPic.push('');
            } else if (this.arrPic.length <= 0) {
                this.arrPic.push('');
            }
        },
        //删除某张图片
        onRemoveImg() {
            this.arrPic = this.arrPic.filter(src => !!src);
            this.onSuccessUploadImg();
            this.arrPic.push(0);
            this.arrPic.pop();
        },
        getRoleList() {
            this.ajaxLoading = true;
            reqGroupList({
                groupId: 6,
            })
                .then(res => {
                    let list = res?.data?.list || [];
                    this.roleList = list;
                })
                .finally(res => {
                    this.ajaxLoading = false;
                });
        },
        //获取详情
        getDetail() {
            if (this.isEdit == 'true') {
                this.showAjaxLoading();
                readAgent({ id: this.formData.id })
                    .then(res => {
                        this.formData = res.errmsg;
                        let arr = JSON.parse(this.formData.area);
                        this.formData.areaarr = arr[0];
                        this.address = arr[1];
                        console.log(this.address, 'address');
                        this.dataInjectFormData(res);
                        // this.arrPic = this.formData.pic.split(this.picSeq);
                    })
                    .finally(() => {
                        this.hideAjaxLoading();
                    });
            }
        },
        //取消
        onCloseEdit() {
            this.$router.back();
            setTimeout(() => {
                this.hideAjaxLoading();
            }, 500);
        },
        //确认
        onConfirm() {
            // this.formData.area = this.address_code;
            // this.formData.dealer_range = this.value1 + ',' + this.value2;
            // this.formData.person_range = this.value3 + ',' + this.value4;
            let params = window.structuredClone(this.formData);
            let {province_settle_days ,dealer_settle_days ,person_settle_days,wd_amount_single_max,wd_amount_day_max,wd_rate,wd_amount_check_min,province_tax_rate, dealer_tax_rate, person_tax_rate} = params || {};

            // let { nickName, userPhone, passWord, groupId, name, userName, userTel } = params || {};
            let vs = [
                { value: province_settle_days, tip: '请选择省代理结算时间' },
                { value: province_tax_rate, tip: '请输入省代理单笔税金' },

                { value: dealer_settle_days, tip: '请选择经销商结算时间' },
                { value: dealer_tax_rate, tip: '请输入经销商单笔税金' },

                { value: person_settle_days, tip: '请选择个人/小B端结算时间' },
                { value: person_tax_rate, tip: '请输入个人单笔税金' },

                { value: wd_amount_single_max, tip: '请输入单笔最大金额' },
                { value: wd_amount_day_max, tip: '请输入每日额度' },
                { value: wd_rate, tip: '请输入提现费率' },
                { value: wd_amount_check_min, tip: '请输入审批条件' },
                // { value: name, tip: '请输入商务商名称' },
                // { value: userName, tip: '请输入联系人' },
                // { value: userTel, tip: '请输入手机号' },
            ];
            // let vs = [];

            hm.validateForm(vs)
                .then(() => {
                    saveFinanceRule(params)
                        .then(res => {
                            this.fadeAlert('设置成功');

                        })
                        .catch(() => {
                            this.hideAjaxLoading();
                        });
                })
                .catch(msg => {
                    this.hideAjaxLoading();
                    this.fadeWarning(msg);
                });
            
        },
        onConfirmSave() {
            console.log(this.formData, 'this.formData');
            let address_code = this.formData.areaarr;
            console.log(getCityTitles(address_code), 'address_title');
            this.showAjaxLoading();
            this.formData.type = 1;
            this.formData.pid = 0;
            let addressObj = [];
            addressObj.push(this.formData.areaarr);
            addressObj.push(getCityTitles(address_code));
            this.formData.area = JSON.stringify(addressObj);
            console.log(this.formData.area, 'this.formData.area');
            let params = window.structuredClone(this.formData);
            // let { nickName, userPhone, passWord, groupId, name, userName, userTel } = params || {};
            // let vs = [
            //     { value: nickName, tip: '请输入账号昵称' },
            //     { value: userPhone, tip: '请输入账号' },
            //     { value: passWord, tip: '请输入密码' },
            //     { value: groupId, tip: '请选择角色' },
            //     { value: name, tip: '请输入商务商名称' },
            //     { value: userName, tip: '请输入联系人' },
            //     { value: userTel, tip: '请输入手机号' },
            // ];
            let vs = [];

            hm.validateForm(vs)
                .then(() => {
                    if (address_code.length <= 0) {
                        this.fadeWarning('请选择公司地址');
                        this.hideAjaxLoading();
                        return;
                    }
                    console.log(address_code, 'address_code');
                    let address_title = getCityTitles(address_code);
                    console.log(address_title, 'address_title');
                    params.address = address_title.join(',') + ';' + this.address_note || '';
                    params.address_code = address_code.join(',');
                    addAgent(params)
                        .then(res => {
                            this.fadeAlert(res.errmsg);

                            this.onCloseEdit();
                        })
                        .catch(() => {
                            this.hideAjaxLoading();
                        });
                })
                .catch(msg => {
                    this.hideAjaxLoading();
                    this.fadeWarning(msg);
                });
        },
        onConfirmEdit() {
            console.log(this.formData, 'this.formData');
            let address_code = this.formData.areaarr;
            console.log(getCityTitles(address_code), 'address_title');
            this.showAjaxLoading();
            this.formData.type = 1;
            this.formData.pid = 0;
            let addressObj = [];
            addressObj.push(this.formData.areaarr);
            addressObj.push(getCityTitles(address_code));
            this.formData.area = JSON.stringify(addressObj);
            console.log(this.formData.area, 'this.formData.area');
            let params = window.structuredClone(this.formData);
            // let { name, userName, userTel } = params || {};
            // let vs = [
            //     { value: name, tip: '请输入商务商名称' },
            //     { value: userName, tip: '请输入联系人' },
            //     { value: userTel, tip: '请输入手机号' },
            // ];
            let vs = [];

            hm.validateForm(vs)
                .then(() => {
                    if (address_code.length <= 0) {
                        this.fadeWarning('请选择公司地址');
                        this.hideAjaxLoading();
                        return;
                    }
                    let address_title = getCityTitles(address_code);
                    params.address = address_title.join(',') + ';' + this.address_note || '';
                    params.address_code = address_code.join(',');
                    updateAgent(params)
                        .then(res => {
                            this.fadeAlert(res.errmsg);

                            this.onCloseEdit();
                        })
                        .catch(() => {
                            this.hideAjaxLoading();
                        });
                })
                .catch(msg => {
                    this.hideAjaxLoading();
                    this.fadeWarning(msg);
                });
        },
    },
};
</script>
<style lang="less" scoped></style>
